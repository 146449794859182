import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/organizations', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/organizations/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/organizations/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/organizations', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/organizations', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/organizations/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        scheme(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/organizationschemes`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDepartmentItems(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/organizations/departments`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDepartmentUnits(ctx, {departmentId}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/organizations/units/${departmentId}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
