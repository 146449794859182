<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex">
          <feather-icon icon="LayersIcon" size="19"/>
          <h4 class="mb-0 ml-50">Demirbaş Kategori Bilgileri</h4>
        </div>

        <b-row class="mt-1">

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="İsim" rules="required">
              <b-form-group label="İsim">
                <b-form-input trim placeholder="İsim" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Açıklama">
              <b-form-group label="Açıklama">
                <b-form-textarea v-model="dataInfo.description" rows="3" placeholder="Açıklama..." :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Sorumlu Departmanlar">
              <b-form-group label="Sorumlu Departmanlar" description="Demirbaş bilgileri bu departmanlara sahip kullanıcılar tarafından görüntülenecektir." :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.organizations" :options="organizationDepartmentOptions" :reduce="val => val.value" :clearable="true" multiple="multiple"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Durum" rules="required">
              <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <action-buttons :back-route="'asset-category-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormTextarea
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/asset/category/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {statusOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";
import organizationModule from "@/views/organization/organization/store";

export default {
  components: {
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_ORGANIZATION_MODULE_NAME = 'store-organization'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_ORGANIZATION_MODULE_NAME, organizationModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_ORGANIZATION_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      name: '',
      description: '',
      isActive: true,
      organizations: []
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'asset-category-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'asset-category-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const organizationDepartmentOptions = ref([])
    busy.value = true
    store.dispatch('store-organization/fetchDepartmentItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          organizationDepartmentOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      organizationDepartmentOptions,

      statusOptions,

      onSubmit,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
